import React, { FC } from 'react';
import BoxSpoiler from '@app/components/ui/box-spoiler';
import useTranslation from '@app/hooks/use-translation';
import { MetaRecords } from '@app/store/reducers/meta.slice';

type RecordMetaProps = { meta?: MetaRecords };

const RecordMeta: FC<RecordMetaProps> = (props) => {
  const { meta } = props;
  const { t } = useTranslation('components.audio');

  const MetaItem: FC<{
    name?: string;
    isAlias: boolean;
    value: string | number;
  }> = ({ name, isAlias, value }) => {
    return (
      <div className="mb-[16px]">
        <div className="inline-flex items-center">
          <div className="text-[15px] text-action mr-[5px]">
            {isAlias ? 'A' : <div className="w-[10px]" />}
          </div>
          <div className="text-3color text-[13px]">{name}</div>
        </div>
        <div className="text-1color text-sm ml-[14px] break-words">{value}</div>
      </div>
    );
  };
  const renderMetaList = meta
    ? Object.keys(meta)
        .filter((key) => key !== 'record_name')
        .map((metaCategory, indexCategory) => {
          if (typeof meta[metaCategory] === 'string')
            return (
              <MetaItem
                key={`category_${indexCategory}`}
                isAlias={false}
                name={metaCategory}
                value={String(meta[metaCategory])}
              />
            );
          return Object.entries(meta[metaCategory]).map(([metaTitle, metaValue], index) => {
            if (metaTitle === 'channels_meta') {
              return Object.entries(metaValue as MetaRecords['channels_meta']).map(
                ([channelNumber, channelValue]) => {
                  const renderValueWithGsm = channelValue?.gsm
                    ? `${t('channels_meta_name')}: ${channelValue.name} \r\n ${t(
                        'channels_meta_gsm',
                      )}: ${channelValue.gsm}`
                    : `${t('channels_meta_name')}: ${channelValue.name}`;
                  return (
                    <MetaItem
                      key={`${metaCategory}-${channelNumber}`}
                      name={`${t('channels_meta_channel')} ${channelNumber}`}
                      isAlias={false}
                      value={renderValueWithGsm}
                    />
                  );
                },
              );
            }
            return (
              <MetaItem key={index} isAlias={false} name={metaTitle} value={String(metaValue)} />
            );
          });
        })
    : [];

  return (
    <BoxSpoiler title={t('meta_data')}>
      <div className="grid grid-cols-2 px-[24px]">{renderMetaList}</div>
    </BoxSpoiler>
  );
};

export default RecordMeta;
